<template>
  <div class="text-left">
    <vca-field>
      <form>
        <vca-input
          ref="password"
          type="password"
          :errorMsg="$t('sign.register.password1.error')"
          :placeholder="$t('sign.reset_pw.password1.placeholder')"
          v-model.trim="password"
          :rules="$v.password"
        >
        </vca-input>
        <vca-input
          ref="password_check"
          type="password"
          :errorMsg="pwError"
          :placeholder="$t('sign.reset_pw.password2.placeholder')"
          v-model.trim="password_check"
          :rules="$v.password_check"
        >
        </vca-input>
        <button class="vca-button" @click.prevent="validate">
          {{ $t("sign.reset_pw.title") }}
        </button>
      </form>
    </vca-field>
  </div>
</template>
<script>
export default {
  name: "ResetPasswordForm",
  data() {
    return {
      password_check: "",
    };
  },
  computed: {
    pwError() {
      if (this.$v.password_check.required && !this.$v.password_check.sameAs) {
        return this.$t("sign.reset_pw.password2.error.same");
      }
      return this.$t("sign.reset_pw.password2.error.enter");
    },
    password: {
      get() {
        return this.$store.state.login.reset_password.reset.password;
      },
      set(value) {
        this.$store.commit("login/reset_password/password", value);
      },
    },
  },
  validations() {
    return this.$store.getters["login/reset_password/validation_reset"];
  },
  methods: {
    validate() {
      if (this.$v.$invalid === true) {
        this.$refs.password.validate();
        this.$refs.password_check.validate();
      } else {
        this.submit();
      }
    },
    submit() {
      this.$store.dispatch({ type: "login/reset_password/finish" }).then(() => {
        this.$emit("success");
      });
    },
  },
};
</script>
