<template>
  <div class="text-left">
    <vca-card>
      <ResetPasswordForm @success="success" />
    </vca-card>
  </div>
</template>
<script>
import ResetPasswordForm from "@/components/ResetPasswordForm";
export default {
  name: "PasswordReset",
  components: {
    ResetPasswordForm,
  },
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  created() {
    this.$store.commit("login/reset_password/code", this.code);
  },
  methods: {
    success() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
